import { Conversation } from 'common/types'
import { LastReadTS } from './LastReadContext'

interface Props {
  conversation: Conversation
  lastReadTS: LastReadTS
}

export const UnreadCount: React.FC<Props> = ({ conversation, lastReadTS }) => {
  const newBubbles = Object.values(conversation).filter((bubble) =>
    lastReadTS ? bubble.timestamp > lastReadTS : true,
  )

  const count = newBubbles.length

  if (count === 0) return null

  return (
    <div className="bg-background-notification flex h-6 w-6 items-center justify-center rounded-full text-sm">
      {count}
    </div>
  )
}
