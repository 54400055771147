import { MessageDescriptor } from '@lingui/core'
import { msg, t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { facilityChannelId, osoChannelId } from 'common/channels'
import { ChannelId } from 'common/types'
import React, { useContext, useMemo } from 'react'
import { ContactType } from 'shared/types/live'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { ConversationContext } from './Communication'
import { Contact } from './Contact'
import { contactsContext } from './contactsProvider'

export const translateChannelNames: Record<ChannelId, MessageDescriptor> = {
  [osoChannelId]: msg`Service clients OSO`,
  [facilityChannelId]: msg`Messagerie interne`,
}

export const Contacts: React.FC<{
  setConversationContext: (_: ConversationContext) => void
  onClose: () => void
}> = ({ setConversationContext, onClose }) => {
  const { uid } = useUser()
  const { contacts, discussions } = useContext(contactsContext)
  const { _: lingui } = useLingui()

  const sortedContactsEntries = useMemo(
    () =>
      Object.entries(contacts ?? {})
        .filter(([userId, _]) => userId !== uid)
        .sort(([_userId1, { name: name1 }], [_userId2, { name: name2 }]) =>
          name1.localeCompare(name2),
        ),
    [uid, contacts],
  )

  const ContactsBlock: React.FC<{ title: string; type: ContactType }> = ({
    title,
    type,
  }) => {
    const contactEntries = sortedContactsEntries.filter(
      ([_, { type: type_ }]) => type_ === type,
    )

    if (contactEntries.length === 0) return null

    return (
      <div className="flex flex-col space-y-4">
        <h2 className="border-b border-current text-xl font-bold">{title}</h2>
        {contactEntries.map(([userId, contact]) => (
          <Contact
            key={userId}
            contact={contact}
            unreadCount={discussions?.[userId]?.unreadCount ?? 0}
            showConversation={() =>
              setConversationContext({ userId, channelId: undefined })
            }
          />
        ))}
      </div>
    )
  }

  const Channels: React.FC<{ title: string }> = ({ title }) => (
    <div className="flex flex-col space-y-4">
      <h2 className="border-b border-current text-xl font-bold">{title}</h2>
      {Object.entries(translateChannelNames).map(([channelId, channelName]) => {
        const trChannelName = lingui(channelName)
        return (
          <Contact
            key={channelId}
            name={trChannelName}
            unreadCount={discussions?.[channelId]?.unreadCount ?? 0}
            showConversation={() =>
              setConversationContext({ channelId, channelName: trChannelName })
            }
          />
        )
      })}
    </div>
  )

  return (
    <>
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col space-y-6">
          <Channels title={t`Groupes`} />
          <ContactsBlock title={t`Contacts`} type="user" />
          <ContactsBlock title={t`Externes`} type="external" />
        </div>
      </div>
      <div className="flex flex-row">
        <Button size="full" variant="secondary" onClick={onClose}>
          <Trans>Fermer</Trans>
        </Button>
      </div>
    </>
  )
}
