import { osoChannelId } from 'common/channels'
import { PanelRight } from 'lucide-react'
import { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { FacilityId } from 'shared/types/utils'
import { cn } from 'shared/utils/web/cn'
import { Button } from '../../../components/ui/Button'
import { facilitiesContext } from '../../../contexts/FacilitiesProvider'
import { contactsContext, ContactsProvider } from '../../Live/contactsProvider'
import { Conversation } from '../../Live/Conversation'
import { LastReadTS, useLastRead } from '../LastReadContext'
import { AlertsList, Panel } from './AlertsPanel'

export const BOTTOM_BORDER_CLASSNAME = 'border border-border/50 shadow-2xl'

export const FacilityPanelLayout = () => {
  const { facilityId } = useParams<{ facilityId: string }>()
  const { facilities } = useContext(facilitiesContext)

  const [showPanel, setShowPanel] = useState<boolean>(false)

  const { lastReadTS, updateLastRead } = useLastRead()

  if (!facilityId) {
    return null
  }

  const facility = facilities[facilityId]

  if (!facility) {
    return (
      <Centered>Erreur : L'établissement demandé est introuvable.</Centered>
    )
  }

  return (
    <div className="text-foreground flex h-full grow flex-col">
      <div
        className={cn(
          'bg-header text-header-foreground flex flex-row justify-between p-2 text-2xl font-bold',
          BOTTOM_BORDER_CLASSNAME,
        )}
      >
        {facility.name}
        <Button
          size="icon"
          variant="secondary"
          className="bg-background text-primary hover:bg-background/60 gap-2 shadow"
          onClick={() => setShowPanel(!showPanel)}
          title="Afficher le panneau des alertes"
        >
          <PanelRight />
        </Button>
      </div>
      <ContactsProvider facilityId={facilityId}>
        <div className="flex h-[calc(100vh-100px)] flex-row justify-center">
          <ConversationLoader
            facilityId={facilityId}
            facilityName={facility.name}
            lastReadTS={lastReadTS[facilityId] ?? 0}
            onUpdateLastRead={updateLastRead}
          />
          {showPanel && (
            <Panel title="Commentaires d'alertes sur les 7 derniers jours">
              <AlertsList facilityId={facilityId} />
            </Panel>
          )}
        </div>
      </ContactsProvider>
    </div>
  )
}

interface ConversationLoaderProps {
  facilityId: FacilityId
  facilityName: string
  lastReadTS: LastReadTS
  onUpdateLastRead: (facilityId: FacilityId, timestamp: number) => void
}

const ConversationLoader: React.FC<ConversationLoaderProps> = ({
  facilityId,
  facilityName,
  lastReadTS,
  onUpdateLastRead,
}) => {
  const { loading, error } = useContext(contactsContext)

  const updateLastReadTS = useCallback(
    (ts: number) => onUpdateLastRead(facilityId, ts),
    [facilityId, onUpdateLastRead],
  )

  if (loading) return <Centered>Chargement...</Centered>
  if (error) return <Centered>Erreur</Centered>

  return (
    <div className="h-full grow">
      <Conversation
        facilityId={facilityId}
        conversationId={osoChannelId}
        conversationContext={{
          channelId: osoChannelId,
          channelName: facilityName,
        }}
        lastReadTS={lastReadTS}
        updateLastReadTS={updateLastReadTS}
      />
    </div>
  )
}
