import { Trans } from '@lingui/macro'
import React from 'react'
import { Zone } from 'shared/types/fleet'
import { translatedTimeRangeString } from 'shared/utils/timeRange.i18n'
import { Room } from './Room'

export const RoomList: React.FC<{
  displayedDevices: [string, FacilityDevice][]
  facility: Facility
  isMonitoring: boolean
  realZone: Zone | undefined
  alertsDate: string
}> = ({ displayedDevices, facility, isMonitoring, realZone, alertsDate }) => {
  const { monitoringTimeRange } = facility

  return (
    <div className="flex flex-col space-y-1 px-1 pt-2">
      {isMonitoring ? (
        <>
          {displayedDevices.map(
            ([
              serial,
              {
                room,
                status,
                monitoringTimeRange: deviceMonitoringTimeRange,
                roomExitTimeRange,
              },
            ]) => (
              <Room
                key={serial}
                serial={serial}
                room={room}
                status={status}
                alertsDate={alertsDate}
                facility={facility.name}
                zone={realZone}
                monitoringTimeRange={
                  deviceMonitoringTimeRange ?? monitoringTimeRange
                }
                roomExitTimeRange={roomExitTimeRange}
              />
            ),
          )}
          {displayedDevices.length === 0 && (
            <div className="bg-background-room flex h-72 flex-col items-center justify-center rounded-lg">
              <Trans>Aucune chambre surveillée</Trans>
            </div>
          )}
        </>
      ) : (
        <div className="pt-72 text-center">
          <b>
            <Trans>Service non actif actuellement</Trans>
          </b>
        </div>
      )}
      <div className="self-center py-4 text-center">
        <Trans>
          Service activé {translatedTimeRangeString(monitoringTimeRange)}
        </Trans>
      </div>
    </div>
  )
}
