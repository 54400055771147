import { Trans } from '@lingui/macro'
import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Centered } from 'shared/components/Centered'
import { FacilitySelector } from '../../components/FacilitySelector'
import { setTheme } from '../../components/Theme'
import { useASUser } from '../../components/UserProvider'
import {
  FacilitiesProvider,
  facilitiesContext,
} from '../../contexts/FacilitiesProvider'
import { useFirebase } from '../../hooks/useFirebase'
import { Admin } from '../Admin/Admin'
import { LastReadProvider } from '../Communication/LastReadContext'
import { Messaging } from '../Communication/Messaging'
import { MultiFacilitiesMessaging } from '../Communication/MultiFacilitiesMessaging'
import { FacilityPanelLayout } from '../Communication/{facilityId}/FacilityPanelLayout'
import { Exports } from '../Exports/Exports'
import { EmergencyCallProvider } from '../Live/EmergencyCallContext'
import { Live } from '../Live/Live'
import { OnCall } from '../OnCall/OnCall'
import { Tabs } from './Tabs'

export const AdminContent: React.FC = () => (
  <FacilitiesProvider>
    <AdminUserContent />
  </FacilitiesProvider>
)

const AdminUserContent = () => {
  // admin content is set in dark mode
  useEffect(() => {
    setTheme('dark')
  }, [])
  return (
    <BrowserRouter>
      <Tabs />
      <Routes>
        <Route path="/alerts" element={<OnCall />} />
        <Route path="/live" element={<LiveSelector />} />
        <Route path="/admin" element={<Admin />} />
        <Route
          path="/messaging"
          element={
            <LastReadProvider>
              <Messaging />
            </LastReadProvider>
          }
        >
          <Route
            index
            element={
              <div className="flex grow items-center justify-center">
                Sélectionnez un établissement
              </div>
            }
          />
          <Route path=":facilityId" element={<FacilityPanelLayout />} />
          <Route path="multi" element={<MultiFacilitiesMessaging />} />
        </Route>
        <Route path="/exports" element={<Exports />} />
        <Route path="*" element={<Navigate to="/alerts" replace />} />
      </Routes>
    </BrowserRouter>
  )
}

const LiveSelector: React.FC = () => {
  const { facility, facilityId } = useContext(facilitiesContext)

  return (
    <>
      <div className="mx-auto my-3 block">
        <FacilitySelector />
      </div>
      <EmergencyCallProvider facilityId={facilityId}>
        <Live facilityId={facilityId} facility={facility} />
      </EmergencyCallProvider>
    </>
  )
}

export const ASContent: React.FC = () => {
  const user = useASUser()
  const {
    data: facility,
    loading,
    error,
  } = useFirebase(`facilities/${user.facilityId}`)

  if (!loading && !error && !facility)
    throw new Error(`Invalid facility ${user.facilityId} for user ${user.uid}`)

  return loading ? (
    <Centered>
      <Trans>Chargement...</Trans>
    </Centered>
  ) : error ? (
    <Centered>
      <Trans>Erreur lors du chargement des données</Trans>
    </Centered>
  ) : !facility ? (
    <Centered>
      <Trans>Établissement invalide</Trans>
    </Centered>
  ) : (
    <EmergencyCallProvider facilityId={user.facilityId}>
      <Live facilityId={user.facilityId} facility={facility} />
    </EmergencyCallProvider>
  )
}
