import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useMemo, useState } from 'react'
import { Language } from 'shared/types/i18n'
import { Deferred } from 'shared/utils/web/deferred'
import { AudioTextInput } from '../../components/AudioTextInput'
import { DialogButton } from '../../components/Button'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { ListItem } from '../../components/ListItem'
import { Button } from '../../components/ui/Button'
import Arrow from '../../icons/arrow.svg?react'
import MessageIcon from '../../icons/message.svg?react'
import PhoneIcon from '../../icons/phone.svg?react'
import PlayButton from '../../icons/play.svg?react'
import Sleeping from '../../icons/sleeping.svg?react'
import Awake from '../../icons/smile.svg?react'
import TV from '../../icons/tv.svg?react'
import { TutorialCarousel } from './TutorialCarousel'
import { isPhoneCallEnabled } from './android'
import { getMediaSourcesByLanguage } from './medias'

const ScreenshotGuide = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="pointer-events-none m-2 flex cursor-default select-none flex-col items-center justify-center bg-black/30">
      <div className="max-h-[95vh] max-w-[95vw] overflow-auto p-2">
        {children}
      </div>
    </div>
  )
}

const ModalOverlayGuide = ({ children }: { children: React.ReactNode }) => {
  return (
    <ScreenshotGuide>
      <div className="max-h-[95vh] max-w-[95vw] overflow-auto p-2">
        <div className="bg-background-dialog text-dialog flex max-h-full flex-col space-y-3 p-3 text-xl">
          {children}
        </div>
      </div>
    </ScreenshotGuide>
  )
}

const AlertHelper1 = () => {
  return (
    <ModalOverlayGuide>
      <div>
        <Trans>Nouvelle alerte Chambre 101</Trans>
        <span>
          <br />
          M. Dupont
        </span>
      </div>
      <div className="flex flex-row">
        <DialogButton>
          <Trans>OK</Trans>
        </DialogButton>
      </div>
    </ModalOverlayGuide>
  )
}

const AlertHelper2 = () => {
  return (
    <ScreenshotGuide>
      <div
        className={`bg-background-alert text-alert relative flex w-full flex-col space-y-3 rounded-xl p-3`}
      >
        <div className="flex flex-row flex-wrap items-center space-x-4">
          <div
            className={`flex size-16 cursor-pointer flex-col items-center justify-center rounded-full border-2 border-current`}
          >
            <PlayButton className="w-8 fill-current" />
          </div>
          <div>
            <b>13:37</b>
          </div>
          <div>
            <Trans>Choc inquiétant</Trans>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <div className="relative">
            <div className="flex flex-col space-y-3">
              <AudioTextInput
                text=""
                onTextChanged={() => {}}
                audioBlob={null}
                onAudioRecorded={() => {}}
                placeholder={t`Votre avis (optionnel)`}
                variant="input"
              />
            </div>
          </div>
          <div className="flex flex-row justify-around">
            <Button variant="outline">
              <Trans>Annuler</Trans>
            </Button>
            <div className="flex-1" />
            <Button variant="submit">
              <Trans>Valider</Trans>
            </Button>
          </div>
        </div>
      </div>
    </ScreenshotGuide>
  )
}

const ZoneHelper = () => {
  return (
    <ModalOverlayGuide>
      <div className="flex cursor-pointer flex-row items-center space-x-2">
        <Arrow className="w-4" fill="currentColor" />
        <span className="flex-1">
          <Trans>Sélection des chambres</Trans>
        </span>
      </div>
      <div className="flex flex-col space-y-3 overflow-y-auto">
        <ListItem>
          <Trans>Toutes</Trans>
        </ListItem>
        <ListItem>
          <Trans>1er étage</Trans>
        </ListItem>
        <ListItem>
          <Trans>2nd étage</Trans>
        </ListItem>
      </div>
      <div className="flex flex-row">
        <DialogButton>
          <Trans>Fermer</Trans>
        </DialogButton>
      </div>
    </ModalOverlayGuide>
  )
}

export const HelpDialog: React.FC<{
  deferred: Deferred<void>
  showZoneSelection: boolean
}> = ({ deferred, showZoneSelection }) => {
  const { i18n } = useLingui()

  const HelpSections = useMemo(() => {
    const mediaSources = getMediaSourcesByLanguage(i18n.locale as Language)
    return {
      video: {
        title: <Trans>Présentation</Trans>,
        content: (
          <video controls autoPlay style={{ objectFit: 'contain' }}>
            <source src={mediaSources.oso_ai_video_source} type="video/mp4" />
            <Trans>Désolé, ce navigateur ne peut pas lire cette vidéo.</Trans>
          </video>
        ),
      },
      alerts: {
        title: <Trans>Comment gérer les alertes</Trans>,
        content: (
          <>
            <Trans>
              Lorsque ARI détecte un bruit inquiétant dans une chambre, une
              alerte est déclenchée sur votre ARI phone en indiquant le numéro
              de la chambre.
            </Trans>

            <AlertHelper1 />

            {window.Android && (
              <Trans>
                Lorsqu'une alerte est déclenchée, votre ARI phone vous le
                notifie par une vibration et un flash lumineux, puis une
                sonnerie.
              </Trans>
            )}

            <Trans>
              Après avoir cliqué sur «OK», vous pouvez écouter le son pour
              déterminer si une intervention auprès du résident est nécessaire.
            </Trans>

            <AlertHelper2 />

            <Trans>
              Vous pourrez évaluer l'utilité de l'alerte en laissant un
              commentaire texte ou vocal.
            </Trans>
          </>
        ),
      },
      live: {
        title: <Trans>Voir l'activité des résidents en temps réel</Trans>,
        content: (
          <>
            <Trans>
              Le ARI phone vous donne des informations en temps réel sur
              l'activité des résidents.
            </Trans>
            <div className="flex flex-row items-center space-x-2">
              <Sleeping className="w-8" fill="currentColor" />
              <span className="flex-1">
                <Trans>Le résident est en train de dormir</Trans>
              </span>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Awake className="w-8" fill="currentColor" />
              <span className="flex-1">
                <Trans>Le résident est actif</Trans>
              </span>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <TV className="w-8" fill="currentColor" />
              <span className="flex-1">
                <Trans>La télé est allumée</Trans>
              </span>
            </div>
          </>
        ),
      },
      zones: {
        title: <Trans>Sélectionner les chambres surveillées</Trans>,
        content: (
          <>
            <Trans>Dans le Menu, appuyer sur "Sélection des chambres".</Trans>

            <Trans>
              Choisir d'afficher et de surveiller toutes les chambres ou
              seulement une partie de l'établissement.
            </Trans>

            <ZoneHelper />
          </>
        ),
      },
      emergencyCall: {
        title: <Trans>Passer un appel d'urgence</Trans>,
        content: (
          <>
            <Trans>
              La fonction <b>Appel d'urgence</b> permet de contacter le ARI
              phone d'un·e autre collègue en cas de situation d'urgence.
            </Trans>
            <Trans>
              Vous pouvez déclencher un appel en appuyant sur le bouton «Appel
              collègue».
            </Trans>

            <div className="bg-background-phone text-menu mx-auto flex flex-1 flex-row items-center justify-center space-x-3 px-8 py-2">
              <PhoneIcon fill="currentColor" className="mr-3 w-6" />
              <Trans>Appel collègue</Trans>
            </div>
          </>
        ),
      },
      emergencyCallDemo: {
        title: <Trans>Tutoriel appel d'urgence</Trans>,
        content: (
          <video controls autoPlay style={{ objectFit: 'contain' }}>
            <source
              src={mediaSources.emergency_call_video_source}
              type="video/mp4"
            />
            <Trans>Désolé, ce navigateur ne peut pas lire cette vidéo.</Trans>
          </video>
        ),
      },
      communication: {
        title: <Trans>Passer un appel</Trans>,
        content: (
          <>
            <Trans>
              Pour voir la liste des téléphones de l'établissement, appuyez sur
            </Trans>
            <div className="bg-background-phone text-menu mx-auto flex flex-1 flex-row items-center justify-center space-x-3 px-8 py-2">
              <PhoneIcon fill="currentColor" className="mr-3 w-6" />
              <Trans>Répertoire</Trans>
            </div>
            <div>
              <Trans>
                Appuyer sur l'icône téléphone pour l'appeler, ou sur celle de la
                messagerie pour discuter.
              </Trans>
              <div className="pointer-events-none flex flex-row justify-evenly">
                <Button size="icon" variant="secondary">
                  <PhoneIcon fill="currentColor" className="h-6" />
                </Button>
                <Button size="icon" variant="secondary">
                  <MessageIcon fill="currentColor" className="h-6" />
                </Button>
              </div>
            </div>
          </>
        ),
      },
      tutorials: {
        title: <Trans>Tutoriels vidéo</Trans>,
        content: <TutorialCarousel isExpanded source="menu" />,
      },
    }
  }, [i18n])

  const [selectedHelpSection, setSelectedHelpSection] = useState<
    keyof typeof HelpSections | null
  >(null)

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      {!selectedHelpSection ? (
        <>
          <div
            className="flex cursor-pointer flex-row items-center space-x-2"
            onClick={() => deferred.reject(USER_BACK_BUTTON)}
          >
            <Arrow className="w-4" fill="currentColor" />
            <span className="flex-1">
              <Trans>Aide à l'utilisation</Trans>
            </span>
          </div>
          <div className="flex flex-col space-y-3 overflow-y-auto">
            <ListItem onClick={() => setSelectedHelpSection('video')}>
              {HelpSections.video.title}
            </ListItem>
            <ListItem onClick={() => setSelectedHelpSection('tutorials')}>
              {HelpSections.tutorials.title}
            </ListItem>
            <ListItem onClick={() => setSelectedHelpSection('alerts')}>
              {HelpSections.alerts.title}
            </ListItem>
            <ListItem onClick={() => setSelectedHelpSection('live')}>
              {HelpSections.live.title}
            </ListItem>
            {showZoneSelection && (
              <ListItem onClick={() => setSelectedHelpSection('zones')}>
                {HelpSections.zones.title}
              </ListItem>
            )}
            {isPhoneCallEnabled() ? (
              <ListItem onClick={() => setSelectedHelpSection('communication')}>
                {HelpSections.communication.title}
              </ListItem>
            ) : (
              <ListItem onClick={() => setSelectedHelpSection('emergencyCall')}>
                {HelpSections.emergencyCall.title}
              </ListItem>
            )}
            <ListItem
              onClick={() => setSelectedHelpSection('emergencyCallDemo')}
            >
              {HelpSections.emergencyCallDemo.title}
            </ListItem>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex cursor-pointer flex-row items-center space-x-2"
            onClick={() => setSelectedHelpSection(null)}
          >
            <Arrow className="w-4" fill="currentColor" />
            <span className="flex-1">
              {HelpSections[selectedHelpSection].title}
            </span>
          </div>

          <div className="flex flex-1 flex-col space-y-2 overflow-auto text-base">
            {HelpSections[selectedHelpSection].content}
          </div>
        </>
      )}

      <div className="flex flex-row">
        <DialogButton onClick={() => deferred.reject(USER_CLOSED_DIALOG)}>
          <Trans>Fermer</Trans>
        </DialogButton>
      </div>
    </Dialog>
  )
}
