import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { FacilityId } from 'shared/types/utils'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'

export type LastReadTS = number | undefined

export type FacilityLastReadTS = Record<FacilityId, LastReadTS>

interface LastReadContextType {
  lastReadTS: FacilityLastReadTS
  updateLastRead: (facilityId: FacilityId, timestamp: number) => void
  markConversationsAsRead: (facilityIds: FacilityId[]) => void
}

const LastReadContext = createContext<LastReadContextType | null>(null)

const LAST_READ_PREFIX = 'lastReadTS_'

export const LastReadProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { facilities } = useContext(facilitiesContext)

  const [lastReadMap, setLastReadMap] = useState<FacilityLastReadTS>({})

  useEffect(() => {
    const initialLastRead = Object.keys(facilities).reduce<FacilityLastReadTS>(
      (acc, facilityId) => {
        const lastRead = localStorage.getItem(
          `${LAST_READ_PREFIX}${facilityId}`,
        )
        acc[facilityId] = lastRead ? parseInt(lastRead, 10) : 0
        return acc
      },
      {},
    )
    setLastReadMap(initialLastRead)
  }, [facilities])

  const updateLastReads = useCallback(
    (facilityIds: FacilityId[], timestamp: number) => {
      facilityIds.forEach((facilityId) => {
        localStorage.setItem(
          `${LAST_READ_PREFIX}${facilityId}`,
          timestamp.toString(),
        )
      })

      setLastReadMap((prev) => {
        const updatedMap = { ...prev }
        facilityIds.forEach((facilityId) => {
          updatedMap[facilityId] = timestamp
        })
        return updatedMap
      })
    },
    [],
  )

  const updateLastRead = useCallback(
    (facilityId: FacilityId, timestamp: number) =>
      updateLastReads([facilityId], timestamp),
    [updateLastReads],
  )

  const markConversationsAsRead = useCallback(
    (facilityIds: FacilityId[]) => updateLastReads(facilityIds, Date.now()),
    [updateLastReads],
  )

  return (
    <LastReadContext.Provider
      value={{
        lastReadTS: lastReadMap,
        updateLastRead,
        markConversationsAsRead,
      }}
    >
      {children}
    </LastReadContext.Provider>
  )
}

export const useLastRead = () => {
  const context = useContext(LastReadContext)
  if (!context) {
    throw new Error('useLastRead must be used within a LastReadProvider')
  }
  return context
}
