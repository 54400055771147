import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { AlertDetails, Quote } from 'common/types'
import { DateTime } from 'luxon'
import React from 'react'
import { isAudioComment, isTextComment } from 'shared/types/alert'
import { ALERT_TYPE_TRANSLATE } from 'shared/types/alert.i18n'
import { dateTimeFromISO } from 'shared/utils/time'
import { AudioPlayer } from '../AudioPlayer'

interface Props {
  quote: Quote
  header?: string
}

const QuoteComponent: React.FC<Props> = ({ quote, header }) => {
  return (
    <blockquote className="rounded-md border-l-4 border-gray-400 bg-gray-100 p-3 pl-4 italic text-gray-700">
      {header && (
        <header className="mb-2 text-sm text-gray-500">{header}</header>
      )}
      {isAudioComment(quote) && <AudioPlayer url={quote.audioCommentURL} />}
      {isTextComment(quote) && <p>"{quote.comment}"</p>}
    </blockquote>
  )
}

export const AlertQuote = ({
  quote,
  details,
}: {
  quote: Quote
  details: AlertDetails
}) => {
  const { type, date, room, email } = details
  const { _: lingui } = useLingui()

  const translatedAlertType = lingui(ALERT_TYPE_TRANSLATE[type])

  const user = email.replace('@oso-ai.com', '')

  const formattedDate = dateTimeFromISO(date).toLocaleString(
    DateTime.DATE_SHORT,
  )

  const formattedAlertContextInfo = t`Alerte (#${room} - ${translatedAlertType}) commentée par "${user}" le ${formattedDate}`

  return <QuoteComponent quote={quote} header={formattedAlertContextInfo} />
}
