import { osoChannelId } from 'common/channels'
import { useOutletContext } from 'react-router-dom'
import { FacilityId } from 'shared/types/utils'
import { GroupComposer } from '../Live/GroupComposer'

export const MultiFacilitiesMessaging = () => {
  const { selectedFacilityIds } = useOutletContext<{
    selectedFacilityIds: Set<FacilityId>
  }>()

  return (
    <div className="flex max-h-[calc(100vh-40px)] grow flex-col">
      <div className="bg-header text-header-foreground p-2 text-2xl font-bold shadow-md">
        Message groupé
      </div>
      <div className="flex grow flex-col justify-between">
        {selectedFacilityIds.size >= 2 ? (
          <>
            <div className="px-2">
              à destination de {selectedFacilityIds.size} établissements
            </div>
            <GroupComposer
              channelId={osoChannelId}
              facilityIds={selectedFacilityIds}
            />
          </>
        ) : (
          <div className="px-2">Sélectionnez plusieurs établissements</div>
        )}
      </div>
    </div>
  )
}
