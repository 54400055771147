import { serverTimestamp } from 'shared/firebase/serverValue'
import {
  AlertEvaluation,
  AlertListener,
  AlertValidation,
} from 'shared/types/alert'

export function alertValidation(uid: string) {
  const value: AlertValidation = {
    uid,
    timeStamp: serverTimestamp(),
    isValidated: true,
    sltLevel: 'skiped',
  }

  return value
}

export function alertEvaluation(uid: string, isConfirmed: boolean) {
  const value: AlertEvaluation = {
    uid,
    isConfirmed,
    timeStamp: serverTimestamp(),
  }

  return value
}

export function alertListener(playProgress: number) {
  const value: AlertListener = {
    playProgress,
    timeStamp: serverTimestamp(),
  }

  return value
}
