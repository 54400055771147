import { FacilityId } from 'shared/types/utils'
import { ChannelId, ConversationId } from './types'

export type ConversationPath =
  `conversations/${FacilityId}/${ConversationId}/${string}`

export const osoChannelId = 'oso'
export const facilityChannelId = 'facility'
export const UPLOAD_IN_PROGRESS = '[UPLOAD_IN_PROGRESS]'
export const ADMIN_USER_ID = 'admin'

export const channelIds: ChannelId[] = [osoChannelId, facilityChannelId]
